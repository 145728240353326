.App {
  text-align: center;
  background: #ddd;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.d-flex-m-hidden{
  display: flex;
}
.d-hidden-m-flex{
  display: none;
}
.d-flex2-m-100{
  flex:2;
}
.d-flex1-m-100{
  flex:1;
}
.d-ml16-m-0{
  margin-left: 16px;
}
.d-ml8-m-0{
  margin-left: 16px;
}
.d-mr16-m-0{
  margin-right: 16px;
}
.d-px64-m-px-16{
  padding-left: 64px;
  padding-right: 64px;
}
.d-text-36-m-24{
  font-size: 36px;
}
.d-text-32-m-24{
  font-size: 32px;
}
.d-text-32-m-20{
  font-size: 32px;
}
.d-text-24-m-14{
  font-size: 24px;
}
.d-text-24-m-12{
  font-size: 24px;
}
.d-text-28-m-22{
  font-size: 28px;
}
.download-button{
  width: 515px;
  height: 86px;
}
.register-button{
  width: 398px;
  height: 67px;
}
.d-pad-24-m-16{
  padding:24px;
}
.d-pad-x-64-m-8{
  padding-left:64px;
  padding-right:64px;
}
.d-w-50-m-100{
  width: 50%;
}
.d-w-32-m-100{
  width: 32%;
}
.header{
  min-height: 105px;
}
.d-text-center-m-text-left{
  text-align: center;
}
.header-logo{
  padding-left: 64px;
}
.d-center-m-start{
  justify-content: center;
}
.d-w-49-m-100{
  width: 49%;
}
.footer-font-1{
  font-size: 20px;
}
.footer-font-2{
  font-size: 12px;
}

@media only screen and (max-width: 1024px) {
  .d-hidden-m-flex{
    display: flex;
  }
  .d-flex-m-hidden{
    display: none;
  }
  .d-flex2-m-100{
    width: 100%;
    flex:none;
  }
  .d-flex1-m-100{
    width: 100%;
    flex:none;
  }
  .d-ml16-m-0{
    margin-left: 0px;
  }
  .d-ml8-m-0{
    margin-left: 0px;
  }
  .d-mr16-m-0{
    margin-right: 0px;
  }
  .d-px64-m-px-16{
    padding-left: 16px;
    padding-right: 16px;
  }
  .d-text-36-m-24{
    font-size: 24px;
  }
  .d-text-32-m-24{
    font-size: 24px;
  }
  .d-text-28-m-22{
    font-size: 22px;
  }
  .d-text-32-m-20{
    font-size: 20px;
  }
  .d-text-24-m-14{
    font-size: 14px;
  }
  .d-text-24-m-12{
    font-size: 12px;
  }
  .download-button{
    width: 307;
    height: 70px;
  }
  .d-pad-24-m-16{
    padding:16px;
  }
  .register-button{
    width: 353px;
    height: 40px;
  }
  .d-pad-x-64-m-8{
    padding-left:0px;
    padding-right:0px;
  }
  .d-w-50-m-100{
    width: 100%;
  }
  .d-w-32-m-100{
    width: 100%;
  }
  .d-w-49-m-100{
    width: 100%;
  }
  
  .header{
    min-height: 60px;
  }
  .d-text-center-m-text-left{
    text-align: left;
  }
  .header-logo{
    padding-left: 24px;
  }
  .d-center-m-start{
    justify-content: left;
  }
  .footer-font-1{
    font-size: 16px;
  }
  .footer-font-2{
    font-size: 8px;
    max-width: 50%;
  }
  
}

full-viewport-height {
  height: 100vh;
}

@supports (height: 100dvh) {
  .full-viewport-height {
    height: 100dvh;
  }
}

.emailinput::placeholder {
  color: #6E604C;
  opacity: 1; /* Firefox */
  font-weight: 700;
}

.emailinput::-ms-input-placeholder { /* Edge 12 -18 */
  color: #6E604C;
  font-weight: 700;
}