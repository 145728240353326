.carousel-container {
    overflow: hidden;
    position: relative;
    height: 100%;
    border-color: #4b5563;
    width: 100%;
}

.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: start;
    align-items: center;
    height: 100%;
    animation: slide 30s linear infinite;
    transition: all 5s;
}

.carousel-item-group{
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 1000px;
}

:root {
    --group_width: -1000px;
    --group_number: 2;
    --animation_end_value: calc(var(--group_width) * var(--group_number));
}

@keyframes slide {
    0% {
        left: 0;
    }
    100% {
        left: -2000px;
    }
}

.bg-gold{
    background: rgb(154,135,117);
    background: linear-gradient(270deg, rgba(154,135,117,1) 0%, rgba(199,172,105,1) 100%, rgba(235,203,96,1) 100%);
}

.sticky-inactive{
}