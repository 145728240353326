@tailwind base;
@tailwind components;
@tailwind utilities;
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.d-show-m-hidden{
  display: show;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text_14{
  font-size: 14px;
}
.grey-bg{
  background-repeat: repeat-y;
  background-color: #ffffff;
}

.statement_text {
  font-size: 14px;
  background: -webkit-linear-gradient(left, #AEA373 , #9B8876);
  background: -o-linear-gradient(right, #AEA373, #9B8876);
  background: -moz-linear-gradient(right, #AEA373, #9B8876);
  background: linear-gradient(to right, #AEA373 , #9B8876); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.vertical_text{
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.appear_bottom_to_top{
  transform: translateY(20%);
  opacity: 0;
  transition: transform 1.2s, opacity 2.5s;
}
.appear_bottom_to_top_active{
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.fly-in-bottom{
  animation: bottomFlyIn 1s ease;
}

@keyframes bottomFlyIn {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}
.appear_opacity{
  animation: opa 1s ease;
}
.appear_opacity_05{
  animation: opa 500ms ease;
}
.appear_opacity_2{
  animation: opa 3s ease;
}
.opacity_0{
  opacity: 0;
  transition: opacity 1s;
}
.opacity_1{
  opacity: 1 !important;
  transition: opacity 1s;
}

@keyframes opa {
  0% {
    opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.scale-img{
  animation: zoom 5s ease-out;
}
@keyframes zoom {
  0% {
    opacity: 0.3;
    transform: scale(1);
  }
  1%{
    opacity: 1;
  }
  99%{
    opacity: 1;
  }
  100% {
      transform: scale(1.1);
      opacity: 0;
  }
}
.div_left_right_container{
  -webkit-text-fill-color: #fff0;
  background-clip: text;
  /* -webkit-background-clip: text; */
  background-image: linear-gradient(90deg, #000 0, #000 42%, #8C866A 47%, #F5E6B6 52%, #e5e5e5 55%, #e5e5e5 100%);
  background-position: 90% 100%;
  transition: background-position 5s;
  background-repeat: no-repeat;
  background-size: 300% 100%;
}
.div_left_right_container_is-active{
  background-position: 0% 100% !important;
}

.flex-row-col{
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  .flex-row-col{
    flex-direction: column;
  }
  .d-show-m-hidden{
    display: none;
  } 
}

.deco_line_ani{
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: all 2s ease-in-out;
}

.deco_line_ani_is-active{
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
}

.place_holder_center{
  text-align: center;
}
.place_holder_center::placeholder{
  text-align: center;
}

.gold_bg_gradient{
  background: rgb(140,134,107);
  background: linear-gradient(180deg, rgba(140,134,107,1) 0%, rgba(153,146,116,1) 26%, rgba(153,146,116,0.40242034313725494) 100%);
}

.btn-gradient-2 {
  background: linear-gradient(#e2e2e2, #e2e2e2) padding-box,
              linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 50em;
  border: 4px solid transparent;
}

.test{
  transform: skewX(35deg);
}

.line-grow {
  width: 0%;
  background: #A7A7A7;
  animation-name: grow;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes grow {
  0% {opacity: 0;}
  5% {opacity: 1;}
  98%{opacity: 1;}
  100% { 
    width: 101%; 
    opacity: 0;
  }
}

.speical-sidebar{
  opacity: 0.6;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100%);
}