.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    font-size: 24px;
    color: #333;
}

.next-arrow {
    right: 0%;
}

.prev-arrow {
    left: 0%;
}

.carousel-item {
    text-align: center;
    padding: 10px;
}

.white-gra{
    background: rgb(230,235,237);
    background: linear-gradient(180deg, rgba(230,235,237,0.26516544117647056) 29%, rgba(255,255,255,1) 100%);
}

.slick-slide div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.caro-title{
    display:inline-block;
    border-top:  1px double #fff;;
    border-right:  1px double #fff;;
    border-bottom:  1px double #fff;;
}
.caro-title-2{
    display:inline-block;
    border-top:  1px double #744F1B;;
    border-right:  1px double #744F1B;;
    border-bottom:  1px double #744F1B;;
}